import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isObject from "lodash/isObject";
import { consentRequired } from "../_helpers/functions";
import moment from "moment-timezone";

export const DialogContext = React.createContext();

const defaultType = {
  header: true,
  holidayHeader: false,
  quizModal: {
    shownTimes: 1,
    showAgain: Date.now(),
  },
  bottom: false,
};

/**
 *
 * @param {node} param0
 * @return {node}
 */
function DialogProvider({ children }) {
  const [dialogType, setDialogType] = useState("");

  useEffect(() => {
    const stateFromStorage = window.localStorage.getItem("frankdarling-dialog");
    const data = stateFromStorage && JSON.parse(stateFromStorage);
    const showHolidayHeader = moment().isSameOrBefore(
      moment("2024-09-08").tz("America/New_York"),
      "day"
    );
    if (data && isObject(data) && isObject(data.quizModal)) {
      setDialogType({ ...data, holidayHeader: showHolidayHeader });
    } else {
      setDialogType(
        consentRequired()
          ? {
              ...defaultType,
              bottom: true,
              holidayHeader: showHolidayHeader,
            }
          : { ...defaultType, holidayHeader: showHolidayHeader }
      );
    }
  }, []);

  useEffect(() => {
    const data = JSON.stringify(dialogType);
    window.localStorage.setItem("frankdarling-dialog", data);
  }, [dialogType]);

  const value = React.useMemo(() => {
    return {
      dialogType,
      setDialogType,
    };
  }, [dialogType, setDialogType]);

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
}

DialogProvider.propTypes = {
  children: PropTypes.node,
};

export default DialogProvider;
